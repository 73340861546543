.portfolio  {
  margin: 0;
  background-color: white;
  color: black;
  /* background: url(../../../public/images/table_top@72x.png); */
  overflow: hidden;
  /* height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

/* .container {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100%;
} */
/* .portfolio-modal {
  position: absolute;
  margin: 5vh 10vw;
  top: 0;
  width: 80vw;
  height: 90vh;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
} 

.portfolio-modal img {
  max-width: 500px;
  height: auto;
} */

.portfolioList {
  font-size: 1rem;
  margin-right: 0.25rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  text-align: center;
}

.portfolio .filter a {
  text-decoration: none;
  color: inherit;
}

.portfolio .filter {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.portfolioList.active {
  background-color: #8FE1F3;
  color: #000000;
}

.portfolioList:hover {
  background-color: #fed46e;
  color: #000000;
}

.portfolio-header {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 25px;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.portfolio-header h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.portfolio-header .specialize {
  margin-bottom: 20px;
}

.portfolio-header span {
  font-weight: 700;
}

.portfolio-header ul li {
  text-align: center;
  width: 15rem;
}

.portfolio ul {
  margin: 10px;
  padding: 0;
  list-style: none;
  display: flex;
  z-index: 1;
}

/* .portfolio .container1 {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.portfolio .portfolio-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;
}

.portfolio .portfolio-item-container .item {
  width: 15rem;
  height: 10rem;
  border-radius: 20px;
  border: 1px solid rgb(240, 239, 239);
  margin: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #EF8181;
  position: relative;
  transition: all .5s ease;
  cursor: pointer;
}


.portfolio .portfolio-item-container .item h3 {
  position: absolute;
  font-size: 1.5rem;
  pointer-events: none;
}

.portfolio .portfolio-item-container .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  border-radius: 0.75rem;
}

.portfolio .portfolio-item-container .item:hover {
  background-color: #8FE1F3;
  cursor: pointer;
}

.portfolio .portfolio-item-container .item img:hover {
  opacity: 0.2;
  z-index: 0;
}

.portfolio .loading {
  /* text-align: center; */
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-aspect-ratio: 3/4), (max-width: 44rem) {
  /* Styles */
  .portfolio .portfolio-item-container {
    flex-direction: column;
  }

  .portfolioList {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .portfolio .filter {
    width: 70%;
    flex-direction: column;
  }

}