.main {
  margin: 0;
}

.parts {
  width: 100%;
  /* height: 500vh; */
  /* position: absolute; */
  display: inline-block;
  /* overflow-y: scroll; */
  /* scroll-behavior: smooth; */
  /* scroll-snap-type: y mandatory; */
}

.parts > * {
  width: 100%;
  min-height: 100vh;
  font-size: 1rem;
  text-size-adjust: auto;
  /* overflow: hidden; */
  /* scroll-snap-align: start; */
}

ul {
  padding-inline-start: 1rem
}

svg.progress-icon {
  height: 100%;
  width: 100%;
}

.progress-container {
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 0;
  text-align: center;
  overflow-y: hidden;
	overflow-x: hidden;
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5) {
  .parts > * {
  font-size: 150%;
}
} */

/* @media only screen and (-webkit-min-device-pixel-ratio: 5/2),
only screen and (min-device-pixel-ratio: 5/2) {
  .parts > * {
  font-size: 200%;
}
} */

.main-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* .line-container {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  z-index: 1000; 
}  */

/* path {
  fill: none;
  stroke: black;
  stroke-width: 3;
  stroke-dasharray: 2566;
  stroke-dashoffset: 2566;
  animation: cloud 5s linear alternate infinite;
}
@keyframes cloud {
  from {
    stroke-dashoffset: 2566;
  }
  to {
    stroke-dashoffset: 0;
  }
} */

/* @media screen and (max-width : 450px) {
  Styles
  svg {
    height: 650vh;
    align-self: center;
  }
} */
