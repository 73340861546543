/* body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #d0e, #91f);
  } */

.skills.container {
  width: 150px;
  height: 150px;
  display: grid;
  overflow: hidden;
  margin: 0;
  list-style: none;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 15px;
  padding: 15px;
  background: #f2c36b;
  border-radius: 50px;
}

.skills.item {
  background: white;
  border-radius: 100%;
}

.image-container.skillsLeaf {
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  background-color: white;
  border-radius: 50%;
  margin: 0.25rem;
}

.image-container.skillsLeaf img {
  width: 100%;
  object-fit: fill;
  border-radius: 30%;
}

.mycircle {
  /* transform-origin: center; */
  /* transition: fill 0.5s ease-in-out, transform 0.5s ease-in-out; */
  transition: fill 0.5s ease-in-out;
}

.mycircle.touchstart {
  fill: #fee7af;
  /* transform: scale(1.04, 1.04); */
}

@media (hover: hover) {
  .mycircle:hover {
    fill: #fee7af;
    /* transform: scale(1.04, 1.04); */
  }
}

/* remember css animations from storysoft */
