.project-container  {
    margin: 0;
    padding: 0;
    background-color: #f0efef;
    color: black;
    /* background: url(../../../public/images/table_top@72x.png); */
    /* overflow: hidden; */
    /* height: 100vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    z-index: 1000;
  }

  .portfolio-modal {
    position: absolute;
    margin: 2.5% 2.5%;
    top: 0;
    /* left: '50%', */
    /* transform: 'translate(-50%, 0)', */
    width: 95%;
    height: 95%;
    background-color: #ffffff;
    border-radius: 0.5rem;
    /* overflow-x: scroll; */
    overflow-y: scroll;
  } 
  
  
  /* .portfolio-modal img {
    width: 25rem;
  } */

  .project-container h1 {
    padding: 0.5rem;
  }

  .project-images-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .project-images-container .carousel {
    max-width: 100%;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .current-image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    /* width: 45rem; */
    height: 26rem;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: #8FE1F3;
    /* overflow-y: scroll; */
    border-radius: 0.5rem;
  }

  .current-image-container:hover {
    background-color: #fed46e;
  }

  .project-container .current-image-container .image-description {
    align-self: center;
    padding-top: 0.5rem;
  }

  .project-images-container a{
    width: 100%;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .project-images-container a .current-image-container img{
    object-fit: contain;
    object-position: left top;
    /* overflow-clip-margin: ; */
    /* overflow-y: scroll; */
    /* border: solid 1rem #8FE1F3; */
    width: 100%;
    height: auto;
  }

  .project-container .close-button {
    position: sticky;
    top: 1rem;
    margin: 1rem 1rem 0rem 0.5rem;
    padding: 0.3rem 0.5rem;
    background-color: #8FE1F3;
    width: fit-content;
    align-self:flex-end;
    color: #EF8181;
    font-weight: 700;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 900;
  }

  .project-container .close-button:hover {
    position: sticky;
    background-color: #fed46e;
    width: fit-content;
    align-self:flex-end;
    color: #EF8181;
    font-weight: 700;
    border-radius: 50%;
    cursor: pointer;
  }

  .thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0.5rem 0.5rem 0.5rem;
    object-fit: contain;
    width: 100%;
    /* height: 100%; */
    border-radius: 0.5rem;
  }

  .thumbnail img {
    border-radius: 0.5rem;
  }

  .current-image-thumbnail img {
    border: solid 0.5rem #8FE1F3;
  }

  .images-container {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    /* pointer-events: none; */
  }

  .images-container ::-webkit-scrollbar {
      width: 0%;
      background: transparent; /* make scrollbar transparent */
  }

  .images-container #slider {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* width: 90%; */
    overflow-x: auto;
    /* pointer-events: none; */
  }

  .images-container #slider img {
    width: 8rem;
    pointer-events: none;
  }

  .x-scroll-progress {
    display: flex;
    flex-direction: row;
  }

  .x-scroll-progress .progress-range {
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #fed46e;
  }

  .x-scroll-progress .progress-range.active {
    background-color: #8FE1F3;
  }

  .project-container .label {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
  } 

  .tags-container, .technologies-container {
    background-color: #8FE1F3;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin: 0.75rem;
  }

  .tags-container .tags, .technologies-container .technologies {
    background-color: rgb(240, 239, 239);
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
  }

  .project-container .description {
    padding: 1rem;
    margin: 1rem;
    border-radius: 0.5rem;
    background-color: #fed46e;
    width: 80%;
  }

  .project-container .description h1 { 
    /* description heading */
    color: #EF8181;
    font-weight: 700;
    display: flex;
    justify-content: center;
    font-size: larger;
    padding: 1rem;
  }

  .project-container .description .item {
    padding: 1rem;
  }

  .project-container .description .item .label {
    color: #EF8181;
    font-weight: 700;
    display: flex;
    justify-content: left;
    font-size: large;
  }

  .project-container .description .item div {
    text-align: left;
    margin-top: 0.25rem;
  }

  .project-container .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem;
    border-radius: 0.5rem;
    padding: 1rem;
    border: 1px solid #EF8181;
    /* width: 50%; */
  }

  .project-container .video-container.landscape {
    width: 60%;
  }

  .project-container .video-container.portrait {
    width: 25%;
  }

  .project-container .video-container .label {
    padding-bottom: 1rem;
  }

  .project-container .video-container video {
    width: 90%;
    padding-bottom: 0.5rem;
  }

  .project-container .projectDate {
    height: 1rem;
    line-height: 1rem;
    background-color: #8FE1F3;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin: 0.75rem;
    margin-bottom: 1.5rem;
  }

  .project-container .projectLink {
    height: 1rem;
    line-height: 1rem;
    background-color: #fed46e;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin: 0.25rem;
    margin-bottom: 1.5rem;
  }

  @media (max-aspect-ratio : 3/4) {
    .project-container .video-container {
      width: 75%;
    }
  }

  /* @media (max-aspect-ratio : 3/4) {
    .current-image-container {
      height: 12rem;
    }

    .project-images-container .current-image-container a img{
      height: 11rem;
    }
  } */

  