.loading_animation_container {
    width: 60%;
}

.left, .right {
    cursor: pointer;
    margin: 0.5rem;
}

.left:hover, .right:hover {
    border: 0.1rem solid #8FE1F3;
    border-radius: 50%;
}

.left svg, .right svg {
    pointer-events: none;
}


  