.education {
  margin: 0;
  background-color: white;
  color: #000000;
}

.education .container {
  display: inline-flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%; 
}

.education-illustration-container {
  width: 50vw;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  position: relative;
}

.education .image-container {
  padding: 5vh 5vw;
  width: auto;
  /* height: 100vh; */
  position: absolute;
}

.education img {
  width: 40vw;
  /* height: 100vh; */
  object-fit: contain;
  /* object-position: 1% 0; */
}

.about-education {
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 0vw 5%;
  text-align: left;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
  position: relative;
}

.about-education h1 {
  font-size: 4rem;
  margin-bottom: 2rem;
}

.about-education .graduation {
  margin-bottom: 25px;
}

.about-education ul {
  margin: 1rem 1rem 1rem 1rem;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.about-education ul li {
  text-align: left;
  width: 100%;
  margin: 0.2rem 0.5rem;
  overflow-wrap: break-word;
  /* background-color: red; */
}

@media (max-aspect-ratio : 3/4) {
  /* Styles */
  .education .container {
    flex-direction: column;
  }

  .about-education {
    width: 90%;
  }

  .about-education ul li {
    width: 90%;
  }

  /* .education-item ul li{
    overflow-wrap: break-word;
  } */

  .education-illustration-container {
    width: 100vw;
  }

  .education .image-container {
    position: relative;
  }

  .education img { 
    width: 90vw;
  }
}