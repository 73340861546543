#root:focus {
  outline: none;
  text-size-adjust: none;
}

.loading {
  /* text-align: center; */
  height: 100vh;
  z-index: 10;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}
